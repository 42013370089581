import { GetUserOrgUnitsResolver } from '@aex/security/shared';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableModule } from 'primeng/table';
import { ComponentHeaderComponent } from './components/component-header/component-header.component';
import { DataGridViewerComponent } from './components/data-grid-viewer/data-grid-viewer.component';
import { ManageActionsComponent } from './components/manage-actions/manage-actions.component';
import { ManageOrgUnitsComponent } from './components/manage-org-units/manage-org-units.component';
import { ManageRolesComponent } from './components/manage-roles/manage-roles.component';
import { ManageUsersComponent } from './components/manage-users/manage-users.component';
import { RoleComponent } from './components/role/role.component';
import { SelectionTableComponent } from './components/selection-table/selection-table.component';
import { SummaryActionDataGridComponent } from './components/summary-action-data-grid/summary-action-data-grid.component';
import { UserComponent } from './components/user/user.component';
import { YesNoDialogComponent } from './components/yes-no-dialog/yes-no-dialog.component';
import { SecurityDialogService } from './services/security-dialog.service';
import { SelectionRoleTableComponent } from './components/selection-table/selection-role-table.component';
import { SelectionOrgUnitTableComponent } from './components/selection-table/selection-org-unit-table.component';

@NgModule({
  declarations: [
    ComponentHeaderComponent,
    DataGridViewerComponent,
    ManageActionsComponent,
    ManageRolesComponent,
    SelectionTableComponent,
    SelectionRoleTableComponent,
    RoleComponent,
    ManageUsersComponent,
    UserComponent,
    ManageOrgUnitsComponent,
    SummaryActionDataGridComponent,
    YesNoDialogComponent,
    SelectionOrgUnitTableComponent,
  ],
  exports: [
    ComponentHeaderComponent,
    DataGridViewerComponent,
    ManageActionsComponent,
    ManageRolesComponent,
    SelectionTableComponent,
    RoleComponent,
    ManageUsersComponent,
    UserComponent,
    ManageOrgUnitsComponent,
    SummaryActionDataGridComponent,
    YesNoDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TableModule,
    MatInputModule,
    MatSlideToggleModule,
    FontAwesomeModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
  ],
  providers: [GetUserOrgUnitsResolver, SecurityDialogService],
})
export class SecurityCommonModule {}
