<div class="flex flex-row items-stretch justify-start">
	<div class="flex flex-row flex-1 justify-start items-center mt-[1rem] gap-2 ">
		<app-component-header pageTitle="Roles and Permissions" class="flex-1" [showBack]="false">
		</app-component-header>
		<div class="flex flex-row gap-2">
			<button mat-raised-button color="primary" (click)="navigateToUrl(systemRolesRoute)"
				class="w-[10rem] admin-nexus-button primary">
				System Roles
			</button>
			<button mat-raised-button color="primary" (click)="navigateToUrl(companyTypesRoute)"
				class="w-[10rem] admin-nexus-button primary">
				Company Types
			</button>
		</div>
	</div>
</div>

<hr />

<div class="w-full" *ngIf="companies?.length > 0">
	<div class="title_box">
		<h5 class="admin-nexus-heading" id="title">
			Company
		</h5>
		<div class="m-[2rem]">
			<mat-form-field appearance="outline" class="w-full">
				<mat-label>
					Select a company
				</mat-label>
				<mat-select [ngModel]="selectedCompany" (selectionChange)="onSelectedCompanyChange($event)">
					<mat-option *ngFor="let company of companies" [value]="company">
						{{company.org_unit_name}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<div class="w-full mt-[2rem] flex flex-row gap-2">
				<button mat-raised-button color="primary" (click)="navigateToUrl(companyRolesRoute)"
					class="w-[12rem] admin-nexus-button primary" [disabled]="!isCompanySelected" *ngIf="mayEnableRoles">
					Roles
				</button>
				<button mat-raised-button color="primary" (click)="navigateToUrl(userRoute)" class="w-[12rem] admin-nexus-button primary"
					[disabled]="!isCompanySelected" *ngIf="mayEnableUsers">
					Users
				</button>
				<button mat-raised-button color="primary" (click)="navigateToUrl(companyAssociationRoute)"
					class="w-[12rem] admin-nexus-button primary" [disabled]="!isCompanySelected" *ngIf="mayEnableCompanyManagement">
					Company Associations
				</button>
			</div>
		</div>
	</div>
</div>

<div class="w-full mt-[2rem]" *ngIf="companies?.length === 0">
	Sorry, you do not have access to any of the companies. Please contact the support to gain access.
</div>