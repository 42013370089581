import { appRoute } from '@aex/ngx-toolbox';

export const SECURITY_ROUTES = {
	login: appRoute('login'),
	knox: appRoute('knox'),
	register: appRoute('register'),
	rolesAndPermissions: appRoute('roles-and-permissions'),
	systemRoles: appRoute('system-roles'),
	manageSystemRole: appRoute('manage-system-role'),
	forgotPassword: appRoute('forgot-password'),
	changePassword: appRoute('change-password'),
	companyTypes: appRoute('company-types'),
	companyRoles: appRoute('company-roles'),
	companyTypesRoles: appRoute('company-type-roles'),
	companyAssociation: appRoute('company-association'),
	manageCompanyAssociationAsAccessor: appRoute('manage-company-association-as-accessor'),
	manageCompanyAssociationAsAccessed: appRoute('manage-company-association-as-accessed'),
	manageCompanyAssociationRoles: appRoute('manage-company-association-roles'),
	cloneRoles: appRoute('clone-roles'),
	manageCompanyRoles: appRoute('manage-company-role'),
	manageUsers: appRoute('manage-users'),
	manageRoleUsers: appRoute('manage-role-users'),
	manageUserRoles: appRoute('manage-user-roles'),
	manageUserCompanyAssociations: appRoute('manage-user-company-associations'),
	roles: appRoute('roles'),
	createCompanyRole: appRoute('create-company-role'),
	newRole: appRoute('new-role'),
	rolesUsers: appRoute('roles-users'),
	users: appRoute('users'),
	user: appRoute('user'),
	createUser: appRoute('create-user'),
	usersRoles: appRoute('manage-user-roles'),
	associationRoles: appRoute('association-roles'),
	associations: appRoute('associations'),
};
