import {
  IPersonUserDto,
	KnoxApiService,
	SECURITY_ROUTE_PARAMS,
	SecurityCompanyService,
} from "@aex/security/shared";
import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Resolve,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class GetUsersResolver implements Resolve<IPersonUserDto[]> {
	constructor(
			private readonly knoxApiService: KnoxApiService,
			private readonly companyService: SecurityCompanyService,
	) {
	}
	public resolve(): Observable<IPersonUserDto[]> {
		const companyId = this.companyService.getCompanyId();

		return this.knoxApiService.getOrgUnitUsers(companyId);
	}
}


@Injectable()
export class GetPersonUserResolver implements Resolve<IPersonUserDto> {

	constructor(private readonly knoxApiService: KnoxApiService) { }

	public resolve(route: ActivatedRouteSnapshot): Observable<IPersonUserDto> {
		const userId = route.params[SECURITY_ROUTE_PARAMS.userId];

		return this.knoxApiService.getPersonUser(userId);
	}
}