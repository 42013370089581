export interface IPagedData<T> {
	page: number;
	count: number;
	total: number;
	items: T[];
}

export enum TItemModificationTag {
	None,
	Inserted,
	Updated,
	Deleted,
	Detected,
	UnDetected,
}

export interface IBaseItem {
	item_modification_tag: TItemModificationTag;
	id?: string;
}

export enum UserType {
  PersonUser,
  Application,
}