import { SVGIcon } from "@progress/kendo-svg-icons";
import { 
  filterDataTypeAsText, 
  TDateRangeOperator, 
  TPromoFilterDataType,
  TPromoStatus,
} from "../helpers/promo-discount-helper";
import { IPromoCustomerService } from "./search-service";
import { Exclude, Type } from "class-transformer";
import { IAdvanceGridSearch } from "./kendo-grid";

export class IYesNo {
  id: boolean;
  name: string;
}

export class IBooleanSelection {
  id?: boolean;
  name: string;
}

export class DateRangeOperatorItem {
  id: TDateRangeOperator;
  name: string;
}

export interface IPromoDateRange {
  selected: boolean;
  filter_data_type: TPromoFilterDataType;
  date_range_operator: TDateRangeOperator;
  date_range_operator_id: number;
  date_start?: Date | null;
  date_end?: Date | null;  
  display_filter_data_type: string;
  display_date_range_operator: string;
}

export interface IIntegerId {
  id: number;
}

export interface IStringId {
  id: string;
}
export interface ISelectionBase extends IStringId {
  name: string;
}

export interface INumberSelectionBase extends IIntegerId {
  name: string;
}

// Details of Promotion Discounts

// Promo Discount Type: Promo or Discount 
export interface IPromoPriceReduction {
  id: number;
  code: string;
  description: string;
}

export interface IPromoBillingFrequency {
  id: number;
  code: string;
  description: string;
}

export interface IPromoCategory {
  id : number;
  category_name: string;
  line_item_description: string;
  benefit_count_applicable: boolean;
  query_type_id: number;
  promo_discount_type_id: number;
  price_type_id: number;
}

export interface IPromoReductionType {
  id: number;
  code: string;
  description: string;
}

export interface IPromoPeriodType extends INumberSelectionBase {
}

// Promo Code
export interface IPromoCodeUsage extends ISelectionBase {
}

export interface IPromoAppliedBy extends ISelectionBase {
}

// Promo-Filter
export interface IPromoArea extends ISelectionBase {
}

export interface IPromoTreeItem extends IPromoArea {
  has_children: boolean;
  children: IPromoTreeItem[];
}

export interface IPromoProduct extends ISelectionBase {
}

export interface IPromoBillingRule extends INumberSelectionBase {
  // charges_activation_pro_rata: boolean | null;
  // charges_installation : boolean | null;
  // has_upfront : boolean | null;
  // billing_scheme_product_type : TBillingSchemeProductType | null;
  // has_splits : boolean | null;
}

export interface IPromoBillingStatus extends ISelectionBase {
}

export interface IPromoServiceStatus extends ISelectionBase { 
}

// Promo Discount Detail
export interface IPromoDiscountDetail {
  name: string;
  gl_code: string;
  description: string;
  promo_price_reduction_id?: number | null;
  promo_billing_frequency_id?: number | null;
  waive_activation_pro_rata: boolean;
  promo_category_id: number | null;     
  promo_line_item_message: string;    // Get initial value from Promo Category
  promo_reduction_type_id?: number | null;
  promo_reduction_amount: number;
  promo_start_date: Date;
  promo_end_date?: Date;
  promo_does_not_expire: boolean;
  promo_period_type_id: number;
  promo_period_length?: number;
  promo_is_perpetual: boolean;
  auto_enlist_customers: boolean;
  category_query_type_id?: number | null;                 // Determines whether filter is to be configured and whether single or multiple services are selected
  category_process_description: string;                   // Category Process Description - UI explanation what will be done
  promo_code: string;                                     // Promo Code - Moved here from Promo Discount Promo Code
}

// Promo Discount Promo Code
export interface IPromoDiscountPromoCode {
  promo_code_required: boolean;
  promo_code: string;
  promo_code_usage_id: string;
  promo_applied_by_id: string;
}

// Promo Discount Filter
export interface IPromoDiscountFilter {
  areas: PromoAreaTreeItem[];
  product_ids: string[];
  service_attribute_item: ServiceAttributeItem;
  billing_attribute_item: BillingAttributeItem;
  billing_rule_ids: number[];
}

export interface IDropDownButtonData{
  code: string, 
  description: string, 
  svgIcon: SVGIcon,
  disabled: boolean,
  action: () => void
}
//#region -- Promo Classes ---
export class PromoPriceReductionItem implements IPromoPriceReduction {
  public id: number = 0;
  public code: string = '';
  public description: string = '';
}

// Promo Discount Item Container for maintaining Promotions
// NOTE: PromoDiscountPromoCodeItem is defined in the class but is not currently since PromoCode has now been moved to PromoDiscountDetailItem
// ManagePromoDiscountPromoCodeComponent will no longer be used for MVP based on meeting held on 19 December 2024.
export class PromoDiscountItem {
  public promo_discount_id: string = '';
  public promo_discount_status_id: TPromoStatus = TPromoStatus.Draft;     // Mapped to active/in-active/draft/deleted
  @Type(() => PromoDiscountDetailItem)
  public promo_discount_detail_item: PromoDiscountDetailItem = new PromoDiscountDetailItem();
  @Type(() => PromoDiscountPromoCodeItem)
  public promo_discount_promo_code_item: PromoDiscountPromoCodeItem = new PromoDiscountPromoCodeItem();
  @Type(() => PromoDiscountFilterItem)
  public promo_discount_filter_item: PromoDiscountFilterItem = new PromoDiscountFilterItem();
  @Type(() => Date)
  public published_date: Date | null = null;                              // Published Date
}

// Promo Discount Detail
export class PromoDiscountDetailItem implements IPromoDiscountDetail {
  public name: string = '';
  public gl_code: string = '';
  public description: string = '';
  public promo_price_reduction_id?: number | null;
  public promo_billing_frequency_id?: number | null;
  public waive_activation_pro_rata: boolean;
  
  public promo_category_id: number | null;
  public promo_line_item_message: string = '';    
  public promo_reduction_type_id?: number | null;
  public promo_reduction_amount: number = 0;
  @Type(() => Date)
  public promo_start_date: Date = new Date();
  @Type(() => Date)
  public promo_end_date?: Date | null;
  public promo_does_not_expire: boolean;
  public promo_period_type_id: number | null;
  public promo_period_length?: number | null;
  public promo_is_perpetual: boolean;
  public auto_enlist_customers: boolean = true;         // True = No Promo Code Required
  public category_query_type_id?: number | null;        // Determines whether filter is to be configured and whether single or multiple services are selected
  public category_process_description: string = '';     // Category Process Description
  public promo_code: string = '';                       // Promo Code
}

// Promo Discount Promo Code
export class PromoDiscountPromoCodeItem implements IPromoDiscountPromoCode {
  public promo_code_required: boolean = false;
  public promo_code: string = ''; 
  public promo_code_usage_id: string = '';
  public promo_applied_by_id: string = '';
}

// Promo Discount Filter
export class PromoDiscountFilterItem implements IPromoDiscountFilter {
  @Type(() => PromoAreaTreeItem)
  public areas: PromoAreaTreeItem[] = [];
  public product_ids: string[] = [];
  @Type(() => ServiceAttributeItem)
  public service_attribute_item: ServiceAttributeItem = new ServiceAttributeItem();
  @Type(() => BillingAttributeItem)
  public billing_attribute_item: BillingAttributeItem = new BillingAttributeItem();
  public billing_rule_ids: number[] = [];
  public billing_product_ids: string[] = [];

  public entity_service_ids: string[] = [];

  // Used by the front-end only
  public selected_promo_customer_services: IPromoCustomerService[] = []

  public get hasFiltersDefined(): boolean {
    return this.areas.length > 0 || 
      this.product_ids.length > 0 || 
      this.entity_service_ids.length > 0 ||
      this.service_attribute_item.hasFiltersDefined() ||
      this.billing_attribute_item.hasFilterDefined() ||
      this.hasBillingSchemeFilterDefined();
  }

  public hasBillingSchemeFilterDefined(): boolean {
    return this.billing_rule_ids.length > 0 || 
      this.billing_product_ids.length > 0;
  }

  public hasEntityServices(): boolean {
    return this.entity_service_ids.length > 0;
  }
}

export class PromoAreaItem implements IPromoArea {
  public id: string = '';
  public name: string = '';
}

export class PromoAreaTreeItem extends PromoAreaItem implements IPromoTreeItem {
  public has_children: boolean = false;
  @Type(() => PromoAreaTreeItem)
  public children: PromoAreaTreeItem[] = [];
}

export class PromoProductItem implements IPromoProduct {
  public id: string = '';
  public name: string = '';
}

export class PromoBillingRuleItem implements IPromoBillingRule {
  public id: number = 0;  
  public name: string = '';
  // public charges_activation_pro_rata: boolean | null;
  // public charges_installation : boolean | null;
  // public has_upfront : boolean | null;
  // public billing_scheme_product_type : TBillingSchemeProductType | null;
  // public has_splits : boolean | null;
}

export class PromoServiceStatusItem implements IPromoServiceStatus {
  public id: string = '';
  public name: string = '';
}

export class PromoBillingStatusItem implements IPromoBillingStatus {
  public id: string = '';
  public name: string = '';
}

//#region --- Promo Date Range Items ---

export abstract class PromoDateRangeItem implements IPromoDateRange {
  public selected: boolean = false;
  public date_range_operator_id = 5;
  @Exclude()
  public get filter_data_type() : TPromoFilterDataType {
    return this.getFilterDataType();
  }
  public date_range_operator: TDateRangeOperator = TDateRangeOperator.After;
  @Type(() => Date)
  public date_start?: Date | null;
  @Type(() => Date)
  public date_end?: Date | null;

  @Exclude()
  public get display_filter_data_type(): string {
    return filterDataTypeAsText(this.filter_data_type)
  }
  @Exclude()
  public display_date_range_operator: string;

  protected abstract getFilterDataType(): TPromoFilterDataType;
}

export class ActivationPromoDateRangeItem extends PromoDateRangeItem {
  protected getFilterDataType() : TPromoFilterDataType {
    return TPromoFilterDataType.ActivationDate;
  }
}

export class ApplicationPromoDateRangeItem extends PromoDateRangeItem {
  protected getFilterDataType() : TPromoFilterDataType {
    return TPromoFilterDataType.ApplicationDate;
  }
}

export class CancellationPromoDateRangeItem extends PromoDateRangeItem {
  protected getFilterDataType() : TPromoFilterDataType {
    return TPromoFilterDataType.CancellationDate;
  }
}

export class PeriodEndPromoDateRangeItem extends PromoDateRangeItem {
  protected getFilterDataType() : TPromoFilterDataType {
    return TPromoFilterDataType.PeriodEndDate;
  }
}

export class SuspensionPromoDateRangeItem extends PromoDateRangeItem {
  protected getFilterDataType() : TPromoFilterDataType {
    return TPromoFilterDataType.SuspensionDate;
  }
}

//#endregion --- Promo Date Range Items ---

export class ServiceAttributeItem {
    @Type(() => ApplicationPromoDateRangeItem)
    public application_date: ApplicationPromoDateRangeItem = new ApplicationPromoDateRangeItem();
    @Type(() => ActivationPromoDateRangeItem)
    public activation_date: ActivationPromoDateRangeItem = new ActivationPromoDateRangeItem();
    @Type(() => CancellationPromoDateRangeItem)
    public cancellation_date: CancellationPromoDateRangeItem = new CancellationPromoDateRangeItem();
    public service_status_ids: string[] = [];

  public hasFiltersDefined(): boolean {
    return this.application_date.selected || 
      this.activation_date.selected || 
      this.cancellation_date.selected || 
      this.service_status_ids.length > 0;
  }
}

export class BillingAttributeItem {
  @Type(() => PeriodEndPromoDateRangeItem)
  public period_end_date: PeriodEndPromoDateRangeItem = new PeriodEndPromoDateRangeItem();
  @Type(() => SuspensionPromoDateRangeItem)
  public suspension_date: SuspensionPromoDateRangeItem = new SuspensionPromoDateRangeItem();
  public in_arrears?: boolean;

  public hasFilterDefined(): boolean {
    return this.period_end_date.selected || 
      this.suspension_date.selected
  }
}

export class PromoCategoryItem implements IPromoCategory {
  public id : number;                         // Promo Category ID
  public category_name: string = '';
  public line_item_description: string = '';
  public benefit_count_applicable: boolean;
  public query_type_id: number;
  public promo_discount_type_id: number;      // Price Reduction Type
  public price_type_id: number;               // Billing Frequency
}

//#endregion -- Promo Classes ---

export class AreaSearchRequestDto {
  public product_ids: string[] = [];
  public billing_rule_ids: number[] = [];
}

export class ProductSearchRequestDto {
  public area_ids: string[] = [];
  public billing_rule_ids: number[] = [];
}

export class BillingRuleRequestDto {
  public area_ids: string[] = [];
  public product_ids: string[] = [];
}

export class PromoDiscountDeleteRequestDto {
  public promo_discount_ids: string[] = [];
}

export class PromoDiscountDataExportRequestDto {
  public format: string = 'csv';
  public promo_discount_fields: string[] = [];
  public filter? : PromoDiscountSearchRequestDto = null;
}

export class PromoDiscountSearchRequestDto {
  public page_number: number = 1;
  public page_size: number = 100;

  public promo_discount_ids: string[] = [];               // Promo Discount IDs
  public promo_name: string = '';                         // Promo Name = contains
  public promo_discount_status_ids: number[] = [];        // Promo Discount Status IDs for Active/FutureDated/Draft/Deleted
  public promo_price_reduction_ids: number[] = [];        // Promo Price Reduction IDs Promo/Discount
  public promo_billing_frequency_ids: number[] = [];      // Promo Billing Frequency IDs
  public promo_category_ids: number[] = [];               // Promo Category IDs
  public promo_reduction_type_ids: string[] = [];         // Promo Reduction Type IDs
  public promo_period_type_ids: number[] = [];            // Promo Period Type IDs
  public promo_start_date: Date | null = null;            // Promo Start Date
  public promo_end_date: Date | null = null;              // Promo End Date
  public promo_period_length: number | null = null;       // Promo Period Length
  public promo_code: string = '';                         // Promo Code = contains
  public promo_area_ids: string[] = [];                   // Promo Area IDs
  public promo_product_ids: string[] = [];                // Promo Product IDs
  public promo_service_status_ids: string[] = [];         // Promo Service Status IDs
  public promo_billing_status_ids: string[] = [];         // Promo Billing Status IDs
  public promo_billing_rule_ids: number[] = [];           // Promo Billing Rule IDs

  @Type(() => PromoDiscountDateRangeItem)
  public promo_discount_date_range_start: PromoDiscountDateRangeItem = new PromoDiscountDateRangeItem();
  @Type(() => PromoDiscountEndDateRangeItem)
  public promo_discount_date_range_end: PromoDiscountEndDateRangeItem = new PromoDiscountEndDateRangeItem();
  @Type(() => PromoDiscountPublishedDateRangeItem)
  public promo_discount_published_date: PromoDiscountPublishedDateRangeItem = new PromoDiscountPublishedDateRangeItem();

  public clearFilters(): void {    
    this.promo_discount_ids = [];         
    this.promo_name = '';                 
    this.promo_discount_status_ids = [];  
    this.promo_price_reduction_ids = [];  
    this.promo_billing_frequency_ids = [];
    this.promo_category_ids = [];         
    this.promo_reduction_type_ids = [];   
    this.promo_period_type_ids = [];      
    this.promo_start_date = null;         
    this.promo_end_date = null;           
    this.promo_period_length = null;      
    this.promo_code = '';                 
    this.promo_area_ids = [];             
    this.promo_product_ids = [];          
    this.promo_service_status_ids = [];   
    this.promo_billing_status_ids = [];   
    this.promo_billing_rule_ids = [];     
  
    this.promo_discount_date_range_start.selected = false;
    this.promo_discount_date_range_end.selected = false;
    this.promo_discount_published_date.selected = false;
  }

  constructor(init?: Partial<PromoDiscountSearchRequestDto>) {
    Object.assign(this, init);
  }  

}

export class PromoDiscountBulkCustomerRequestDto {
  public promo_discount_item: PromoDiscountItem;
  public entity_service_ids: string[] = [];               // Entity Service IDs
}

// Promo Discount Info : This is the promo-discount information that will be returned for the Promotions and Discount Grid
// Promo Discount Data Item for the Promotions Grid
export class PromoDiscountDataItem {
  public id : string;                                         // Promo Discount Record ID
  public collector_id: string;                                // Collector ID
  public promo_discount_type_id: number;                      // Promo Discount Type ID = Price Reduction Id
  public promo_category_id : number;                          // Promo Category ID
  public eligibility_filter_id : string | null;               // Eligibility Filter ID
  public name : string | null;                                // Promo Discount Name
  public description : string | null;                         // Promo Discount Description
  public line_item_message : string | null;                   // Line Item Message - Appears on invoice
  public promo_discount_status_id : TPromoStatus = TPromoStatus.Draft; // Promo status = published
  public published_date	: Date | null;                        // Published Date
  public auto_enlist : boolean;                               // Auto Enlist Customers: Promo Code Required or Not
  public promo_code	: string | null;                          // Promo Code
  public gl_code : string | null;                             // GL Code
  public goal_or_marketing_message : string | null;           // Goal or Marketing Message
  @Type(() => Date)
  public start_date : Date;                                   // Promo Start Date
  @Type(() => Date)
  public end_date : Date | null;                              // Promo End Date 
  public service_usage_count : number = 0;
  public price_type_id : number;                              // Price Type ID: Monthly
  public waive_activation_pro_rata : boolean;                 // Waive Activation Pro Rata
  public adjustment_type_id : number;                         // Adjustment Type ID = Type of Reduction: additive, subtractive, substitution
  public adjustment_value : number | null;                    // Value : Amount of adjustment type 
  public period_length : number | null;                       // Period Length
  public category_query_type_id : number;                     // Category Query Type ID: Single Service or Multiple Services

  // Additional Fields associated with the Id's above
  public collector_name: string;                      // Collector Name
  public status: string;                              // Must correspond to promo_status_id
  public promo_discount_type_name: string;            // Promo Discount Type Name
  public promo_category_name	: string;               // Promo Category Name
  public price_type_name : string;                    // Price Type Name  
  public adjustment_type_name : string;               // Adjustment Type Name 

  public promo_status_class: string;                  // Promo Status Class

  constructor(init?: Partial<PromoDiscountDataItem>) {
    Object.assign(this, init);
  }  
}

export type ColumnDataType = 'string' | 'number' | 'boolean' | 'date';

export type FilterDataType = 'text' | 'numeric' | 'boolean' | 'date';

export class BulkCustomerResponseDto
{
  public promo_id : string;
  public services_updated : string[] = [];
  public services_deleted : string[] = [];
}

export enum TCustomerSearchAction {
  NoBulking,
  BulkAdd,
  BulkDelete,
}

export class PromoDiscountDateRangeItem extends PromoDateRangeItem {
  protected getFilterDataType(): TPromoFilterDataType {
    return TPromoFilterDataType.PromoDiscountDate;
  }
}

export class PromoDiscountEndDateRangeItem extends PromoDateRangeItem {
  protected getFilterDataType(): TPromoFilterDataType {
    return TPromoFilterDataType.PromoDiscountEndDate;
  }
}

export class PromoDiscountPublishedDateRangeItem extends PromoDateRangeItem {
  protected getFilterDataType(): TPromoFilterDataType {
    return TPromoFilterDataType.PromoDiscountPublishedDate;
  }
}

export class PromoDiscountAdvSearchFilter extends PromoDiscountSearchRequestDto implements IAdvanceGridSearch {
  public anyFiltersDefined(): boolean {
    return this.promo_discount_ids.length > 0 ||
      this.promo_name.length > 0 ||
      this.promo_code.length > 0 ||
      this.promo_discount_status_ids.length > 0 ||
      this.promo_discount_date_range_start.selected ||
      this.promo_discount_date_range_end.selected ||
      this.promo_discount_published_date.selected;
  }
}

export class PromoDiscountStatusItem implements INumberSelectionBase {
  public id: number = 0;
  public name: string = '';
}
