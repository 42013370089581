import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiBaseService } from "./api-base.service";

import { PricingServiceApi } from "@aex/shared/apis";

import { 
  AreaSearchRequestDto,
  BillingRuleRequestDto,
  BulkCustomerResponseDto,
  IPromoAppliedBy,
  IPromoBillingFrequency, 
  IPromoBillingRule, 
  IPromoBillingStatus, 
  IPromoCategory,
  IPromoCodeUsage,
  IPromoPeriodType,
  IPromoPriceReduction,
  IPromoReductionType,
  IPromoServiceStatus,
  ProductSearchRequestDto,
  PromoAreaTreeItem,
  PromoDiscountBulkCustomerRequestDto,
  PromoDiscountDataExportRequestDto,
  PromoDiscountDataItem,
  PromoDiscountDeleteRequestDto,
  PromoDiscountItem,
  PromoDiscountSearchRequestDto,
  PromoProductItem,
} from "@aex/shared/common-lib";
import { IPagedResponse } from "@aex/ngx-toolbox";

const PRICING_SERVICE_GENERAL_ERROR_TEXT = 'pricingService';

@Injectable({
	providedIn: 'root',
})
export class PricingServiceApiService extends ApiBaseService {

	constructor(http: HttpClient) {
    super(http);
  }

	protected override getHandlerErrorText(): string {
		return PRICING_SERVICE_GENERAL_ERROR_TEXT;
	}

	// Get Promo Price Reductions List
  public getPromoPriceReductionList(): Observable<IPromoPriceReduction[]> {
    const url = `${PricingServiceApi.promoDiscountTypeList()}`;
    return this.get<IPromoPriceReduction[]>(url);
  }

	// Get Promo Billing Frequency list / Price Type List
  public getPromoBillingFrequencyList(): Observable<IPromoBillingFrequency[]> {
    const url = `${PricingServiceApi.promoPriceTypeList()}`;
    return this.get<IPromoBillingFrequency[]>(url);
  }

	// Get Promo Category list
  public getPromoCategoryList(): Observable<IPromoCategory[]> {
    const url = `${PricingServiceApi.promoCategoryList()}`;
    return this.get<IPromoCategory[]>(url);
  }

	// Get Promo Reduction Type list
  public getPromoReductionTypeList(): Observable<IPromoReductionType[]> {
    const url = `${PricingServiceApi.promoReductionTypeList()}`;
    return this.get<IPromoReductionType[]>(url);
  }

	// Get Promo Period Type list
  public getPromoPeriodTypeList(): Observable<IPromoPeriodType[]> {
    const url = `${PricingServiceApi.promoPeriodTypeList()}`;
    return this.get<IPromoPeriodType[]>(url);
  }

	// Get Promo Code Usage list
  public getPromoCodeUsageList(): Observable<IPromoCodeUsage[]> {
    const url = `${PricingServiceApi.promoCodeUsageList()}`;
    return this.get<IPromoCodeUsage[]>(url);
  }

  // Get Promo Applied By list
  public getPromoAppliedByList(): Observable<IPromoAppliedBy[]> {
    const url = `${PricingServiceApi.promoAppliedByList()}`;
    return this.get<IPromoAppliedBy[]>(url);
  }

  public getPromoAreaList(areaSearchRequestDto : AreaSearchRequestDto): Observable<PromoAreaTreeItem[]> {
    const url = `${PricingServiceApi.promoAreaTreeList()}/get`;
    return this.post<AreaSearchRequestDto, PromoAreaTreeItem[]>(
      url,
      areaSearchRequestDto,
    );
  }

  public getPromoProductList(productSearchRequestDto : ProductSearchRequestDto): Observable<PromoProductItem[]> {
    const url = `${PricingServiceApi.promoProductList()}/get-products`;
    return this.post<ProductSearchRequestDto, PromoProductItem[]>(
      url,
      productSearchRequestDto,
    );
  }

  public getPromoBillingRuleList(billingRuleRequestDto : BillingRuleRequestDto): Observable<IPromoBillingRule[]> {
    const url = `${PricingServiceApi.promoBillingRuleList()}/get`;
    return this.post<BillingRuleRequestDto, IPromoBillingRule[]>(
      url,
      billingRuleRequestDto,
    );
  }

  public getPromotionDiscountData(searchRequest: PromoDiscountSearchRequestDto): Observable<IPagedResponse<PromoDiscountDataItem>> {
    const url = `${PricingServiceApi.promotionDiscount()}/search`;
    return this.post<PromoDiscountSearchRequestDto, IPagedResponse<PromoDiscountDataItem>>(
      url,
      searchRequest,
    );
  }

  public getPromoServiceStatusList(): Observable<IPromoServiceStatus[]> {
    const url = `${PricingServiceApi.promoServiceStatusList()}/get/service-statuses`;
    return this.get<IPromoServiceStatus[]>(url);
  }

  public getPromoBillingStatusList(): Observable<IPromoBillingStatus[]> {
    const url = `${PricingServiceApi.promoBillingStatusList()}`;
    return this.get<IPromoBillingStatus[]>(url);
  }

  public deletePromotions(deleteRequest: PromoDiscountDeleteRequestDto) : Observable<void> {
    const url = `${PricingServiceApi.promotionDiscount()}/delete-list`;
    return this.post<PromoDiscountDeleteRequestDto, void>(
      url,
      deleteRequest,
    );
  }

  // Get Promotion Discount by Id
  public getPromotionDiscountById(promoId: string): Observable<PromoDiscountItem> {
    const url = `${PricingServiceApi.promotionDiscount()}/${promoId}`;
    return this.get<PromoDiscountItem>(url);
  }

  public createPromotionDiscount(promoDiscountItem : PromoDiscountItem): Observable<PromoDiscountItem> {
    const url = `${PricingServiceApi.promotionDiscount()}/create`;
    return this.post<PromoDiscountItem, PromoDiscountItem>(
      url,
      promoDiscountItem,
    );
  }

  public updatePromotionDiscount(promoDiscountItem : PromoDiscountItem): Observable<PromoDiscountItem> {
    const url = `${PricingServiceApi.promotionDiscount()}/update/${promoDiscountItem.promo_discount_id}`;
    return this.put<PromoDiscountItem, PromoDiscountItem>(
      url,
      promoDiscountItem,
    );
  }

  public bulkAddCustomerPromoDiscount(promoId: string, updateRequestDto: PromoDiscountBulkCustomerRequestDto): Observable<BulkCustomerResponseDto> {
    const url = `${PricingServiceApi.promotionDiscount()}/update/${promoId}/add-bulk-services`;
    return this.put<PromoDiscountBulkCustomerRequestDto, BulkCustomerResponseDto>(
      url,
      updateRequestDto,
    );
  }

  public bulkDeleteCustomerPromoDiscount(promoId: string, updateRequestDto: PromoDiscountBulkCustomerRequestDto): Observable<BulkCustomerResponseDto> {
    const url = `${PricingServiceApi.promotionDiscount()}/update/${promoId}/remove-bulk-services`;
    return this.put<PromoDiscountBulkCustomerRequestDto, BulkCustomerResponseDto>(
      url,
      updateRequestDto,
    );
  }

  public exportPromotionDiscountData(promoDiscountDataExportRequestDto : PromoDiscountDataExportRequestDto): Observable<HttpResponse<Blob>> {
    const url = `${PricingServiceApi.promotionDiscount()}/export`;
    return this.blobPost<PromoDiscountDataExportRequestDto>(
      url,
      promoDiscountDataExportRequestDto,
    );
  }

  // Get Promotion Discount Report by Id
  public getPromotionDiscountReportById(promoId: string): Observable<PromoDiscountDataItem> {
    const url = `${PricingServiceApi.promotionDiscount()}/${promoId}/report`;
    return this.get<PromoDiscountDataItem>(url);
  }
  
}
