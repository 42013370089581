import { SECURITY_ROUTES } from '@aex/security/shared';
import { AuthService } from '@aex/shared/root-services';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-home-container',
	template: `
		<div class="flex flex-col h-screen">
			<header class="header">
				<div class="flex flex-row justify-between items-center">
					<div class="header-logo"></div>
					<button mat-raised-button color="primary" (click)="logOut()" class="admin-nexus-button primary">
						Log Out
					</button>
				</div>
			</header>
			<main class="flex-1 overflow-y-auto">
				<div class="security-content mx-auto">
					<router-outlet></router-outlet>
				</div>
			</main>
			<footer class="flex flex-col justify-center items-center">
				<small class="copyright">
					All rights reserved. Integrated Network Management System © Automation Exchange 2024
				</small>
			</footer>
		</div>
	`,
	styleUrls: ['./home-container.component.scss'],
})
export class HomeContainerComponent {
	title = 'admin-nexus';

	constructor(
		private readonly router: Router,
		private readonly authService: AuthService,
	) {}

	public logOut(): void {
		this.authService.logout().subscribe();
		this.router.navigateByUrl(SECURITY_ROUTES.login.path).then();
	}
}
