import {
	IOrgUnitDto,
	SECURITY_ROUTES,
	SecurityCompanyService,
} from '@aex/security/shared';
import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { isNil } from 'lodash';

@Component({
	selector: 'app-admin-nexus-dashboard',
	templateUrl: './dashboard.component.html',
})
export class DashboardComponent {
	public companies: IOrgUnitDto[];
	public selectedCompany: IOrgUnitDto;
	public get isCompanySelected(): boolean {
		return !isNil(this.selectedCompany);
	}

	// Route Urls
	public systemRolesRoute: string = SECURITY_ROUTES.systemRoles.name;
	public companyTypesRoute: string = SECURITY_ROUTES.companyTypes.name;
	public companyRolesRoute: string = SECURITY_ROUTES.companyRoles.name;
	public userRoute: string = SECURITY_ROUTES.users.name;
	public companyAssociationRoute: string = SECURITY_ROUTES.companyAssociation.name;

	// Set to false in LIVE
	// Security Properties
	public mayEnableRoles: boolean = true;
	public mayEnableUsers: boolean = true;
	public mayEnableCompanyManagement: boolean = true;

	constructor(
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly companyService: SecurityCompanyService,
	) {
		this.route.data.subscribe(({ companies }) => {
			this.companies = companies;
			const selectedCompanyId = this.companyService.getCompanyId();
			if (selectedCompanyId)
				this.selectedCompany = this.companies.find(
					(vSearchItem: IOrgUnitDto) => vSearchItem.id === selectedCompanyId,
				);
		});
	}

	public navigateToUrl(url: string): void {
		this.router.navigateByUrl(`security/${url}`).then();
	}

	public onSelectedCompanyChange(event: MatSelectChange): void {
		this.selectedCompany = event.value;
    this.companyService.setCompanyDto(this.selectedCompany);
		this.companyService.setCompanyId(this.selectedCompany?.id);
		this.companyService.setCompanyName(this.selectedCompany?.org_unit_name);
	}
}
