import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthType, ParamMetaData,IPersonDto } from "@aex/shared/common-lib";
import { CompanyManagementServiceApi } from "@aex/shared/apis";
import { map } from "rxjs/operators";
import { ICompanyEmployeeDto } from "@aex/security/shared";

@Injectable({
	providedIn: 'root',
})
export class PersonService {
	constructor(
			private readonly http: HttpClient,
	) {
	}

	public insertPerson(personDto: IPersonDto): Observable<IPersonDto> {
		const personList: IPersonDto[] = [];
		personList.push(personDto)

		return this.insertPersonList(
				personList,
		).pipe(
			map(
				(response: IPersonDto[]): IPersonDto =>{
					return response[0];
				},
			),
		)
	}

  public insertCompanyPerson(companyEmployeeDto : ICompanyEmployeeDto)
  {
		return this.http.post<ICompanyEmployeeDto>(
				CompanyManagementServiceApi.CompanyPersonsEmployees(),
				companyEmployeeDto,
				{
					params: new ParamMetaData({authToken: AuthType.USER}),
				},
		);
  }

	public insertPersonList(personList: IPersonDto[]): Observable<IPersonDto[]> {
		return this.http.post<IPersonDto[]>(
				CompanyManagementServiceApi.Persons(),
				personList,
				{
					params: new ParamMetaData({authToken: AuthType.USER}),
				},
		);
	}
}
