import { LoaderType } from '@aex/ngx-toolbox';
import { KnoxApi } from '@aex/shared/apis';
import {
	AuthType,
	ENVIRONMENT_CONFIG_TOKEN,
	IEnvironment,
	KEY_AUTH_TOKEN, KEY_USER_ID,
	Operators,
	ParamMetaData,
	Urls,
} from '@aex/shared/common-lib';
import { AuthService, BaseConfigService } from '@aex/shared/root-services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ILoginRequestDto, ILoginResponseDto } from '../interfaces/knox';

@Injectable()
export class SecurityAuthService extends AuthService {

	constructor(
		http: HttpClient,
		router: Router,
		configService: BaseConfigService,
		@Inject(ENVIRONMENT_CONFIG_TOKEN) environment: IEnvironment,
	) {
		super(http, router, configService, environment);
	}

	public authLogin(
		username: string,
		password: string,
		loader?: LoaderType,
	): Observable<ILoginResponseDto> {
		const loginRequestDto: ILoginRequestDto = {
			login_id: username,
			password: password,
		};

		let headerID = this.configService.fnoName.toUpperCase();
		if (headerID.toLowerCase() === Operators.AutomationExchange)
			headerID = '';

		return this.http.post<ILoginResponseDto>(
				KnoxApi.knoxUrlGeneral(Urls.Auth),
				loginRequestDto,
				{
					headers: new HttpHeaders()
						.set('x-operator-id', headerID)
						.set('X-ORG-UNIT-ID', headerID),
					params: new ParamMetaData({
						handleError: 'authentication',
						authToken: AuthType.NONE,
						loader,
					}),
				},
			).pipe(
				tap((loginResponseDto: ILoginResponseDto) => {
					this.processLoginResponse(loginResponseDto);
				}),
			);
	}

	public processLoginResponse(loginResponseDto: ILoginResponseDto): void {
		if (loginResponseDto.is_successful) {
			this.configService.store(KEY_USER_ID, loginResponseDto.user_id);

			this.setAuthToken(loginResponseDto.jwt);
		}
	}

	public override setAuthToken(value: string): void {
		super.setAuthToken(value);
		this.configService.store(KEY_AUTH_TOKEN, value);
	}

	protected override getToken(key: string): string {
		return this.configService.retrieve(key);
	}
}
